
import axios from '@/eap/axios'
let service = axios;
//export default service

export default function (cfg) {
  if ((window.isElectron || top.window.isElectron)) {
    var urlsCallback
    if (window.electronCfg)
      urlsCallback = window.electronCfg.urlCallback
    if (top.window.electronCfg)
      urlsCallback = top.window.electronCfg.urlCallback
    for (var key in urlsCallback) {
      if (cfg.url.indexOf(key) > -1)
        return urlsCallback[key](cfg);
    }
  }
  if(typeof(cfg.timeout)!="undefined"){
    cfg.isCustomTimeout=true;
  }
  return axios(cfg);
} 