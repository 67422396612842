import hiSetting from '@/hiSetting';
// 审批意见
let getApprovalInfo = ({ request, url, ftaskguid, flowtype }) => {
    let promise
    let dataUrl = url || hiSetting.getApprovalInfo
    if (ftaskguid) {
        if (dataUrl.indexOf('?') == -1)
            dataUrl = dataUrl + "?ftaskguid=" + ftaskguid;
        else
            dataUrl = dataUrl + "&ftaskguid=" + ftaskguid;
    }
    if (flowtype) {
        if (dataUrl.indexOf('?') == -1)
            dataUrl = dataUrl + "?flowtype=" + flowtype;
        else
            dataUrl = dataUrl + "&flowtype=" + flowtype;
    }

    promise = request({
        url: dataUrl,
        method: 'post'
    })
    return promise
}

// 处理流程
let processFlow = ({ request, url, data, param, timeout }) => {
    let promise;
    let dataUrl = url || hiSetting.processFlow;
    let __data = {
        __body: JSON.stringify(data),
    }
    if (param) {
        Object.assign(__data, param)
    }
    let requestParam = {
        url: dataUrl,
        data: __data,
        method: 'post'
    }
    if (timeout) {
        Object.assign(requestParam, { timeout: timeout })
    }
    promise = request(requestParam)
    return promise
}

// 任务中心查询
let queryTask = ({ request, url, param }) => {
    let promise;
    let dataUrl = url || hiSetting.queryTask;
    promise = request({
        url: dataUrl,
        data: param,
        method: 'post'
    })
    return promise
}
// 打开流程接口
let getFlowInfo = ({ request, url, data }) => {
    let promise;
    promise = request({
        url: url || hiSetting.getFlowInfo,
        data: data,
        method: 'post'
    })
    return promise
}

// 单号打开流程接口
let openOrdernumber = ({ request, fordernumber, url }) => {
    let promise;
    let dataUrl = url || hiSetting.openOrdernumber;
    let openurl = `${dataUrl}?fordernumber=${fordernumber}`
    promise = request({
        url: openurl,
        method: 'get'
    })
    return promise
}
// 撤回
let withdrawtask = ({ request, url, ftaskguid }) => {
    let promise;
    let dataUrl = url || hiSetting.withdrawtask;
    let path = `${dataUrl}?ftaskguid=${ftaskguid}`
    promise = request({
        url: path,
        method: 'get'
    })
    return promise
}

// 反审
let umpireOrder = ({ request, url, fordernumber }) => {
    let promise;
    let dataUrl = url || hiSetting.umpireOrder;
    let path = `${dataUrl}?fordernumber=${fordernumber}`
    promise = request({
        url: path,
        method: 'get'
    })
    return promise
}

// 流程监控
let taskDiagram = ({ request, url, forderguid, fmodelpath, fversion }) => {
    let promise;
    let dataUrl = url || hiSetting.taskDiagram;
    let path = `${dataUrl}?fmodelpath=${fmodelpath}${fversion ? ("&fversion=" + fversion) : ""}`
    if (forderguid) {
        path = path + "&forderguid=" + forderguid
    }
    promise = request({
        url: path,
        method: 'get'
    })
    return promise
}
// 流程监控
let ganttChart = ({ request, url, fordernumber }) => {
    let promise; let path;
    let dataUrl = url || hiSetting.ganttChart;
    if (fordernumber) {
        path = dataUrl + "?fordernumber=" + fordernumber
    }
    promise = request({
        url: path,
        method: 'get'
    })
    return promise
}

export default {
    getApprovalInfo,
    processFlow,
    queryTask,
    getFlowInfo,
    withdrawtask,
    taskDiagram,
    ganttChart,
    openOrdernumber,
    umpireOrder
}
